import {TopographyEightEntries,TopographyNineEntries} from "./TopographyTemplates";
    import {React, useState} from "react";
    import barycentre_image from "../../assets/images/barycentre.png";
    import delambre_image from "../../assets/images/delambre.png";

    import TopographyAffiliate from "./TopographyAffiliate";

function TopographyThreePoints(){
    const [selectedText, setSelectedText] = useState('barycentre') //default selected value
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400   flex justify-center items-center py-2">Three Points Bearing</h1>
                    <p>
                    Three-point bearing (Delambre and Barycentre methods) allows to determine the position of a point from three other points that have aimed at it, 
                    but to do this we need to know the coordinates of each of the three points as well as the angular reading of each of the sights.
                    In the form below, enter the coordinates of the Three points A, B, C and angles to determine the coordinates of the Point S using either the Delambre Method or the Barycentre Method.
                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-12">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="barycentre">Barycentre</option>
                                <option value="delambre">Delambre</option>
                            </select>
                        </div>
                        <div>
                            {selectedText==="barycentre" && 
                            <TopographyNineEntries endpoint={"relevement/barycentre"} image={barycentre_image} 
                            placeHolder1={"XA"} placeHolder2="YA" placeHolder3={"XB"} placeHolder4="YB"  placeHolder5={"XC"} placeHolder6="YC" placeHolder7="Angle a in gradians"  placeHolder8={"Angle b in gradians"} placeHolder9="Angle c in gradians">
                                </TopographyNineEntries>}
                                {selectedText==="delambre" && 
                            <TopographyEightEntries endpoint={"relevement/delambre"} image={delambre_image} 
                            placeHolder1={"XA"} placeHolder2="YA" placeHolder3={"XB"} placeHolder4="YB"  placeHolder5={"XC"} placeHolder6="YC" placeHolder7="Angle a in gradians"  placeHolder8={"Angle b in gradians"} >
                                </TopographyEightEntries>}

                            
                           
                        </div>
                        <div className="py-12"> 
                        {/*  <TopographyAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default TopographyThreePoints;