import React from "react";
import {Helmet} from "react-helmet";
import bearing_image from "../../assets/images/four-common-misconcept-3.jpg";
import circular_image from "../../assets/images/3d-rendered-bubbles-purple-background_167650-249.avif";
import change_image from "../../assets/images/13-8_Quantum-Time_Lead.webp";
import triangle_image from "../../assets/images/glossy-triangles.jpg";
import intersection_image from "../../assets/images/black-background-with-shapes-golden-elements_52683-21948.avif";
import three_image from "../../assets/images/3d-triangle_1045-126.avif";
import figures_image from "../../assets/images/polygonal-sphere-design_1114-27.avif";

function TopographyMain(){
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Topographic calculations." />
          <meta property="og:description" content="Covers bearing and distance, changes of coordinate systems, intersections, triangle resolutions."/>
        </Helmet>        
            {/**Description */}
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-2 mx-auto">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="text-2xl mx-auto font-bold text-sky-500 py-4">Topography</h1>
                        <p className="text-black-500 dark:text-white">
                        Topography covers, among other things, the calculations of bearing and distance,  
                        changes of coordinate systems, triangle resolutions, intersections of straight lines and circles as well as bearings on three points.
                        Here is an exhaustive list of the topics covered: 
                        </p>
                        <ul className="space-y-4 py-4 px-12">
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Bearing and distance</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Circular and parabolic segments</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Changes of coordinate systems</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Triangle resolutions</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Intersections of lines and circles</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Bearings on three points</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm">Areas of complex figures</p>
                            </li>

                        </ul>
                        <div className=" dark:text-white">
                            <span>The native Application can be downloaded on the different applications stores: </span>
                            <a className="btn btn-ghost btn-circle btn-sm mr-4" href="https://play.google.com/store/apps/details?id=tntechnology.app.topography" target={"_blank"} rel="noopener noreferrer">
                                <svg width="20px" height="20px" viewBox="19.932999999999996 68.509 228.155 228.155" xmlns="http://www.w3.org/2000/svg"><path d="M101.885 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24z" fill="#a4c639"/><path d="M69.374 133.645c-.047.54-.088 1.086-.088 1.638v92.557c0 9.954 7.879 17.973 17.66 17.973h94.124c9.782 0 17.661-8.02 17.661-17.973v-92.557c0-.552-.02-1.1-.066-1.638H69.374z" fill="#a4c639"/><path d="M166.133 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24zM46.405 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c-.001-7.865 6.375-14.242 14.241-14.242zM221.614 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c0-7.865 6.376-14.242 14.241-14.242zM69.79 127.565c.396-28.43 25.21-51.74 57.062-54.812h14.312c31.854 3.073 56.666 26.384 57.062 54.812H69.79z" fill="#a4c639"/><path d="M74.743 70.009l15.022 26.02M193.276 70.009l-15.023 26.02" fill="none" stroke="#a4c639" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/><path d="M114.878 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04zM169.874 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04z" fill="#ffffff"/></svg>
                                </a>
                                <a className="btn btn-ghost btn-circle btn-sm" href="https://www.microsoft.com/store/apps/9MT7B62XSKCR" target={"_blank"} rel="noopener noreferrer">
                                <svg width="20px" height="20px" viewBox="-0.5 0 257 257" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M0 36.357L104.62 22.11l.045 100.914-104.57.595L0 36.358zm104.57 98.293l.08 101.002L.081 221.275l-.006-87.302 104.494.677zm12.682-114.405L255.968 0v121.74l-138.716 1.1V20.246zM256 135.6l-.033 121.191-138.716-19.578-.194-101.84L256 135.6z" fill="#00ADEF"/></svg>
                                </a> . <span  className="text-black-500 dark:text-white">You will find below the sub-topics of Topography. Please select one of the options to proceed further.</span> 
                            </div>
                    </div>
                </div>
            </section>
            {/**Topics */}
            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-5">
                        <div className="p-4 lg:w-1/3">
                            {/**Bearing and distance*/}
                            <div className="card shadow w-80 m-4  dark:bg-slate-800">
                                <figure>
                                    <img src={bearing_image} alt="polynomial" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title"> Bearing and distance between two points</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Calculate the bearing and distance between two points.
                                    </p>
                                    <a className="btn bg-blue-500 hover:bg-blue-800 text-white btn-sm" href="/topography-bearing">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">
                            {/**Circular and parabolic segments */}
                            <div className="card shadow w-80 m-4  dark:bg-slate-800">
                                <figure>
                                    <img src={circular_image} alt="complex" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Circular and parabolic segments</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Calculate areas and volumes of circular segments.
                                    </p>
                                    <a className="btn bg-purple-500 hover:bg-purple-800 text-white btn-sm" href="/topography-circular">Access</a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 lg:w-1/3">
                            {/**Changes of coordinate systems*/}
                            <div className="card shadow w-80 m-4  dark:bg-slate-800">
                                <figure>
                                    <img src={change_image} alt="ineq" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Changes of coordinate systems</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Determine the coordinates of a point in another projection.
                                    </p>
                                    <a className="btn bg-red-400 hover:bg-red-800 text-white btn-sm" href="/topography-coordinate">Access</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-5">
                        <div className="p-4 lg:w-1/3">
                            {/**Triangle resolutions*/}
                            <div className="card shadow w-80 m-4  dark:bg-slate-800">
                                <figure>
                                    <img src={triangle_image} alt="slope" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Triangle resolutions</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Determine angles and distances on a triangle.
                                    </p>
                                    <a className="btn bg-red-500 hover:bg-red-800 text-white btn-sm" href="/topography-triangle">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">
                            {/**Intersections of straight lines and circles */}
                            <div className="card shadow w-80 m-4  dark:bg-slate-800">
                                <figure>
                                    <img src={intersection_image} alt="fibo" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Intersections of straight lines and circles</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Calculate the coordinates of points corresponding to the intersections of straight lines and circles.
                                    </p>
                                    <a className="btn bg-gray-500 hover:bg-gray-800 text-white btn-sm" href="/topography-intersection">Access</a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 lg:w-1/3">
                            {/**Bearings on three points*/}
                            <div className="card shadow w-80 m-4  dark:bg-slate-800">
                                <figure>
                                    <img src={three_image} alt="prime" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Bearings on three points</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Calculate the bearing on three points.
                                    </p>
                                    <a className="btn bg-blue-400 hover:bg-blue-800 text-white btn-sm" href="/topography-three">Access</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-5">
                        <div className="p-4 lg:w-1/3">
                            {/**Areas*/}
                            <div className="card shadow w-80 m-4  dark:bg-slate-800">
                                <figure>
                                    <img src={figures_image} alt="slope" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Areas of geometric figures</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Calculate the areas of complex geometric figures.
                                    </p>
                                    <a className="btn bg-teal-700 hover:bg-teal-800 text-white btn-sm" href="/topography-area">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">

                        </div>

                        <div className="p-4 lg:w-1/3">

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default TopographyMain;
