import {CalculusOneMatrixEntry, CalculusOneMatrixInputEntry,
   AngouriMatrixGuide, CalculusTwoMatricesEntries}
 from "./CalculusTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import CalculusAffiliate from "./CalculusAffiliate";



function CalculusMatrice(){
    const [selectedText, setSelectedText] = useState('inv') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Matrices" />
          <meta property="og:description" content="Transpose, find inverse, determinant, diagonale of a matrix. Add, subtract and multiply matrices."/>
        </Helmet>         
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Matrices</h1>
                    <p>A matrix is a rectangular array of numbers arranged in rows and columns. 
                        There are different types of matrices: <br />
                        &#9724; Square Matrix: Same number of rows and columns. <br />
                        &#9724; Diagonal Matrix: All off-diagonal elements are zero. <br />
                        &#9724; Identity Matrix: Diagonal elements are 1, and all others are zero. <br />
                        &#9724; Zero Matrix: All elements are zero. <br />
In the form below, you can apply various operations to matrices.
                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="inv">Inverse of a square matrix</option>
                                <option value="det">Determinant of a square matrix</option>
                                <option value="diag">Diagonal of a square matrix</option>
                                <option value="trace">Trace of a square matrix</option>
                                <option value="trans">Transpose a matrix</option>
                                <option value="power">Raise a matrix to the power n</option>
                                <option value="mulscalar">Multiply a matrix with a scalar n</option>
                                <option value="add">Add two matrices of same dimensions</option>
                                <option value="sub">Subtract two matrices of same dimensions</option>
                                <option value="multwo">Multiply two matrices</option>
                            </select>
                        </div>
                    </div>
                        <div>
                            {selectedText==="inv" && <> <CalculusOneMatrixEntry endpoint={"onematrix/inverse"}  ></CalculusOneMatrixEntry>   </>}     
                            {selectedText==="det" && <> <CalculusOneMatrixEntry endpoint={"onematrix/determinant"}  ></CalculusOneMatrixEntry>   </>}  
                            {selectedText==="diag" && <> <CalculusOneMatrixEntry endpoint={"onematrix/diagonal"}  ></CalculusOneMatrixEntry>   </>}  
                            {selectedText==="trace" && <> <CalculusOneMatrixEntry endpoint={"onematrix/trace"}  ></CalculusOneMatrixEntry>   </>}  
                            {selectedText==="trans" && <> <CalculusOneMatrixEntry endpoint={"onematrix/transpose"}  ></CalculusOneMatrixEntry>   </>} 

                            {selectedText==="power" && <> <CalculusOneMatrixInputEntry endpoint={"onematrix/power"} ></CalculusOneMatrixInputEntry>   </>} 
                            {selectedText==="mulscalar" && <> <CalculusOneMatrixInputEntry endpoint={"onematrix/scalar"}  ></CalculusOneMatrixInputEntry>   </>} 

                            {selectedText==="add" && <> <CalculusTwoMatricesEntries endpoint={"twomatrices/add"} sign={"+"}  ></CalculusTwoMatricesEntries>   </>}
                            {selectedText==="sub" && <> <CalculusTwoMatricesEntries endpoint={"twomatrices/sub"} sign={"-"}  ></CalculusTwoMatricesEntries>   </>}
                            {selectedText==="multwo" && <> <CalculusTwoMatricesEntries endpoint={"twomatrices/prod"} sign={"*"}  ></CalculusTwoMatricesEntries>   </>}
                        </div>
                        <div className="card shadow w-full">
                             <AngouriMatrixGuide ></AngouriMatrixGuide> 
                        </div>
                        <div className="py-12"> 
                        {/*  <CalculusAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default CalculusMatrice;