import {LinearSystemGuide, CalculusLinearSystems}
 from "./CalculusTemplates";
    import {React} from "react";
    import {Helmet} from "react-helmet";
    import CalculusAffiliate from "./CalculusAffiliate";



function CalculusSystem(){
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Linear systems" />
          <meta property="og:description" content="Solve systems of linear equations."/>
        </Helmet> 
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Systems of Linear Equations</h1>
                    <p>A system of linear equations is a collection of two or more linear equations involving the same set of variables.
                        In the following form, you can solve various systems of linear equations.

                    </p><br /><hr /><br />
                        <div>
                            <> <CalculusLinearSystems endpoint={"linearsystems"} sign={"X="} ></CalculusLinearSystems>   </>     
                        </div>
                        <div className="card shadow w-full">
                             <LinearSystemGuide ></LinearSystemGuide> 
                        </div>
                        <div className="py-12"> 
                        {/*  <CalculusAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default CalculusSystem;