import {React, useState} from "react";
import AlgebraAffiliate from "./AlgebraAffiliate";

let Complex = require('complex.js');

function ComplexAdd(){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [outputText, setOutputText] = useState('')
    const startCalculate =() => { 
        try {

            var a = Complex(searchItem1);
            var b = Complex(searchItem2);
            var op = a.add(b);
            setOutputText(op.toString());
        } catch (error) {
            setOutputText(error);
        }   
    }

    return(
        <div>

            <div className="flex flex-row py-2">
                <div className="basis-3/7">
                    <input  type="text" placeholder="Compl. (ex: 5+2i )" className="input input-bordered w-full ml-2"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                </div>
                <div className="basis-1/7">
                    <p className="items-center m-4">+</p>
                </div>
                <div className="basis-3/7">
                <input  type="text" placeholder="Compl. (ex: 7-i )" className="input input-bordered w-full mr-1"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                </div>
                
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function ComplexSub(){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [outputText, setOutputText] = useState('')
    const startCalculate =() => { 
        try {

            var a = Complex(searchItem1);
            var b = Complex(searchItem2);
            var op = a.sub(b);
            setOutputText(op.toString());
        } catch (error) {
            setOutputText(error);
        }   
    }

    return(
        <div>

            <div className="flex flex-row py-2">
                <div className="basis-3/7">
                    <input  type="text" placeholder="Compl. (ex: 5+2i )" className="input input-bordered w-full ml-2"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                </div>
                <div className="basis-1/7">
                    <p className="items-center m-4">-</p>
                </div>
                <div className="basis-3/7">
                <input  type="text" placeholder="Compl. (ex: 7-i )" className="input input-bordered w-full mr-1"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                </div>
                
            </div>
            <div className="py-2">
                <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function ComplexMul(){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [outputText, setOutputText] = useState('')
    const startCalculate =() => { 
        try {

            var a = Complex(searchItem1);
            var b = Complex(searchItem2);
            var op = a.mul(b);
            setOutputText(op.toString());
        } catch (error) {
            setOutputText(error);
        }   
    }

    return(
        <div>

            <div className="flex flex-row py-2">
                <div className="basis-3/7">
                    <input  type="text" placeholder="Compl. (ex: 5+2i )" className="input input-bordered w-full ml-2"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                </div>
                <div className="basis-1/7">
                    <p className="items-center m-4">*</p>
                </div>
                <div className="basis-3/7">
                <input  type="text" placeholder="Compl. (ex: 7-i )" className="input input-bordered w-full mr-1"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                </div>
                
            </div>
            <div className="py-2">
                <button className="btn bg-purple-600 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function ComplexDiv(){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [outputText, setOutputText] = useState('')
    const startCalculate =() => { 
        try {

            var a = Complex(searchItem1);
            var b = Complex(searchItem2);
            var op = a.div(b);
            setOutputText(op.toString());
        } catch (error) {
            setOutputText(error);
        }   
    }

    return(
        <div>

            <div className="flex flex-row py-2">
                <div className="basis-3/7">
                    <input  type="text" placeholder="Compl. (ex: 5+2i )" className="input input-bordered w-full ml-2"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                </div>
                <div className="basis-1/7">
                    <p className="items-center m-4">/</p>
                </div>
                <div className="basis-3/7">
                <input  type="text" placeholder="Compl. (ex: 7-i )" className="input input-bordered w-full mr-1"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                </div>
                
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}


function ComplexPower(){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [outputText, setOutputText] = useState('')
    const startCalculate =() => { 
        try {

            var a = Complex(searchItem1);
            var b = searchItem2;
            var op = a.pow(b);
            setOutputText(op.toString());
        } catch (error) {
            setOutputText(error);
        }   
    }

    return(
        <div>

            <div className="flex flex-row py-2">
                <div className="basis-1/2">
                    <input  type="text" placeholder="Complex (ex: 5+2i )" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                </div>
                <div className="basis-1/2">
                <input  type="text" placeholder="n" className="input input-bordered w-full ml-1"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                </div>
                
            </div>
            <div className="py-2">
                <button className="btn bg-purple-600 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}


function ComplexSquareRoot(){
    const [searchItem1, setSearchItem1] = useState('')
    const [outputText, setOutputText] = useState('')
    const startCalculate =() => { 
        try {

            var a = Complex(searchItem1);
            var op = a.sqrt();
            setOutputText(op.toString());
        } catch (error) {
            setOutputText(error);
        }   
    }

    return(
        <div>

            <div className="">
                <input  type="text" placeholder="Compl. (ex: 5+2i )" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input> 
            </div>
            <div className="py-2">
                <button className="btn bg-orange-700 hover:bg-orange-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function ComplexLog(){
    const [searchItem1, setSearchItem1] = useState('')
    const [outputText, setOutputText] = useState('')
    const startCalculate =() => { 
        try {

            var a = Complex(searchItem1);
            var op = a.log();
            setOutputText(op.toString());
        } catch (error) {
            setOutputText(error);
        }   
    }

    return(
        <div>

            <div className="">
                <input  type="text" placeholder="Complex (ex: 5+2i )" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input> 
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}
function ComplexAngle(){
    const [searchItem1, setSearchItem1] = useState('')
    const [outputText, setOutputText] = useState('')
    const startCalculate =() => { 
        try {

            var a = Complex(searchItem1);
            var op = a.arg();
            setOutputText(op.toString());
        } catch (error) {
            setOutputText(error);
        }   
    }

    return(
        <div>

            <div className="">
                <input  type="text" placeholder="Complex (ex: 5+2i )" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input> 
            </div>
            <div className="py-2">
                <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}


function ComplexMagnitude(){
    const [searchItem1, setSearchItem1] = useState('')
    const [outputText, setOutputText] = useState('')
    const startCalculate =() => { 
        try {

            var a = Complex(searchItem1);
            var op = a.abs();
            setOutputText(op.toString());
        } catch (error) {
            setOutputText(error);
        }   
    }

    return(
        <div>

            <div className="">
                <input  type="text" placeholder="Complex (ex: 5+2i )" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input> 
            </div>
            <div className="py-2">
                <button className="btn bg-purple-600 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function ComplexInverse(){
    const [searchItem1, setSearchItem1] = useState('')
    const [outputText, setOutputText] = useState('')
    const startCalculate =() => { 
        try {

            var a = Complex(searchItem1);
            var op = a.inverse();
            setOutputText(op.toString());
        } catch (error) {
            setOutputText(error);
        }   
    }

    return(
        <div>

            <div className="">
                <input  type="text" placeholder="Complex (ex: 5+2i )" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input> 
            </div>
            <div className="py-2">
                <button className="btn bg-orange-700 hover:bg-orange-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function ComplexConjugate(){
    const [searchItem1, setSearchItem1] = useState('')
    const [outputText, setOutputText] = useState('')
    const startCalculate =() => { 
        try {

            var a = Complex(searchItem1);
            var op = a.conjugate();
            setOutputText(op.toString());
        } catch (error) {
            setOutputText(error);
        }   
    }

    return(
        <div>

            <div className="">
                <input  type="text" placeholder="Complex (ex: 5+2i )" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input> 
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}


function AlgebraComplex(){
    const [selectedText, setSelectedText] = useState('add') //default selected value
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Complex Numbers</h1>
                    <p>Complex numbers extend the real numbers by including the imaginary unit, i, where 
i <sup>2</sup> = -1. They are in the form a + bi, where a and b are real numbers.
                    </p><br /><hr /><br />

                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="add">Add</option>
                                <option value="sub">Subtract</option>
                                <option value="mul">Multiply</option>
                                <option value="div">Divide</option>
                                <option value="pow">Raise a complex to the power n</option>
                                <option value="sqrt">Square root of a complex number</option>
                                <option value="log">Natural logarithm of a complex number</option>
                                <option value="magnitude">Magnitude of a complex number</option>
                                <option value="angle">Angle of a complex number</option>
                                <option value="inv">Inverse of a complex number</option>
                                <option value="conj">Conjugate of a complex number</option>
                            </select>
                        </div>
                    </div>
                        <div>
                            {selectedText==="add" && <> <ComplexAdd /></>}     
                            {selectedText==="sub" && <> <ComplexSub /></>} 
                            {selectedText==="mul" && <> <ComplexMul /></>} 
                            {selectedText==="div" && <> <ComplexDiv /></>} 
                            {selectedText==="pow" && <> <ComplexPower /></>} 
                            {selectedText==="sqrt" && <> <ComplexSquareRoot /></>} 
                            {selectedText==="log" && <> <ComplexLog /></>} 
                            {selectedText==="magnitude" && <> <ComplexMagnitude /></>} 
                            {selectedText==="angle" && <> <ComplexAngle /></>} 
                            {selectedText==="inv" && <> <ComplexInverse /></>} 
                            {selectedText==="conj" && <> <ComplexConjugate /></>} 
                        </div>
                        <div className="py-12"> 
                        {/*  <AlgebraAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    
                </div>
            </section>
            </>
    )
}

export default AlgebraComplex;