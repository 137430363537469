import { BrowserRouter , Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import About from "./components/About";
import Contact from "./components/Contact";
import Term from "./components/Term";
import Policy from "./components/Policy";

import Disclaimers from "./components/Disclaimers";
import Products from "./components/Products";
import AlgebraMain from "./components/algebra/AlgebraMain";
import CalculusMain from "./components/calculus/CalculusMain";
import TrigonometryMain from "./components/trigonometry/TrigonometryMain";
import StatisticsMain from "./components/statistics/StatisticsMain";
import StatisticsBasic from "./components/statistics/StatisticsBasic";
import StatisticsContinuous from "./components/statistics/StatisticsContinuous";
import StatisticsDiscrete from "./components/statistics/StatisticsDiscrete";
import SurfaceMain from "./components/surface/SurfaceMain";
import TopographyMain from "./components/topography/TopographyMain";
import FinanceMain from "./components/finance/FinanceMain";
import ScienceMain from "./components/science/ScienceMain";
import FunctionsMain from "./components/functions/FunctionsMain";
import SurfaceArea from "./components/surface/SurfaceArea";
import SurfaceVolume from "./components/surface/SurfaceVolume";
import TrigonometryIdentity from "./components/trigonometry/TrigonometryIdentity";
import TrigonometryConverter from "./components/trigonometry/TrigonometryConverter";
import TrigonometryFunction from "./components/trigonometry/TrigonometryFunction";
import TrigonometryEquation from "./components/trigonometry/TrigonometryEquation";
import TrigonometryCircle from "./components/trigonometry/TrigonometryCircle";
import TopographyBearing from "./components/topography/TopographyBearing";
import TopographyCircular from "./components/topography/TopographyCircular";
import TopographyVolume from "./components/topography/TopograhyVolume";
import TopographyIntersection from "./components/topography/TopographyIntersection";
import TopographyThreePoints from "./components/topography/TopographyThreePoints";
import TopographyTriangle from "./components/topography/TopographyTriangle";
import TopographyCoordinate from "./components/topography/TopographyCoordinate";
import AlgebraPolynomial from "./components/algebra/AlgebraPolynomial";
import AlgebraComplex from "./components/algebra/AlgebraComplex";
import AlgebraInequality from "./components/algebra/AlgebraInequality";
import AlgebraSlope from "./components/algebra/AlgebraSlope";
import AlgebraFibonacci from "./components/algebra/AlgebraFibonacci";
import AlgebraPrime from "./components/algebra/AlgebraPrime";
import CalculusIntegral from "./components/calculus/CalculusIntegral";
import CalculusDerivative from "./components/calculus/CalculusDerivative";
import CalculusLimit from "./components/calculus/CalculusLimit";
import CalculusMatrice from "./components/calculus/CalculusMatrice";
import CalculusSystem from "./components/calculus/CalculusSystem";
import ScienceMath from "./components/science/ScienceMath";
import SciencePhysics from "./components/science/SciencePhysics";
import ScienceSolar from "./components/science/ScienceSolar";
import FinanceActivity from "./components/finance/FinanceActivity";
import FinanceBasic from "./components/finance/FinanceBasic";
import FinanceDebt from "./components/finance/FinanceDebt";
import FinanceDepreciation from "./components/finance/FinanceDepreciation";
import FinanceLiquidity from "./components/finance/FinanceLiquidity";
import FinanceMarkets from "./components/finance/FinanceMarkets";
//import FinanceFinancialMarkets from "./components/finance/FinanceFinancialMarkets";
import FinanceProfitability from "./components/finance/FinanceProfitability";
import FinanceBanking from "./components/finance/FinanceBanking";
import FinanceCorporate from "./components/finance/FinanceCorporate";
import FinanceGeneral from "./components/finance/FinanceGeneral";
import FinanceStocks from "./components/finance/FinanceStocks";
import Cookies from "./components/Cookies";







const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path = "/" element = { <Layout/>}>
            <Route index element = { <Home/> }></Route>

            <Route path ="algebra" element = { <AlgebraMain/> } ></Route>
            <Route path ="algebra-polynomial" element = { <AlgebraPolynomial/> } ></Route>
            <Route path ="algebra-complex" element = { <AlgebraComplex/> } ></Route>
            <Route path ="algebra-inequality" element = { <AlgebraInequality/> } ></Route>
            <Route path ="algebra-slope" element = { <AlgebraSlope/> } ></Route>
            <Route path ="algebra-fibonacci" element = { <AlgebraFibonacci/> } ></Route>
            <Route path ="algebra-prime" element = { <AlgebraPrime/> } ></Route>

            <Route path ="calculus" element = { <CalculusMain/> } ></Route>
            <Route path ="calculus-integral" element = { <CalculusIntegral/> } ></Route>
            <Route path ="calculus-derivative" element = { <CalculusDerivative/> } ></Route>
            <Route path ="calculus-matrice" element = { <CalculusMatrice/> } ></Route>
            <Route path ="calculus-system" element = { <CalculusSystem/> } ></Route>
            <Route path ="calculus-limit" element = { <CalculusLimit/> } ></Route>

            <Route path ="trigonometry" element = { <TrigonometryMain/> } ></Route>
            <Route path ="trigonometry-identity" element = { <TrigonometryIdentity/> } ></Route>
            <Route path ="trigonometry-converter" element = { <TrigonometryConverter/> } ></Route>
            <Route path ="trigonometry-function" element = { <TrigonometryFunction/> } ></Route>
            <Route path ="trigonometry-equation" element = { <TrigonometryEquation/> } ></Route>
            <Route path ="trigonometry-circle" element = { <TrigonometryCircle/> } ></Route>

            <Route path ="surface" element = { <SurfaceMain/> } ></Route>
            <Route path ="surface-area" element = { <SurfaceArea/> } ></Route>
            <Route path ="surface-volume" element = { <SurfaceVolume/> } ></Route>

            <Route path ="topography" element = { <TopographyMain/> } ></Route>
             <Route path ="topography-bearing" element = { <TopographyBearing/> } ></Route>
            <Route path ="topography-circular" element = { <TopographyCircular/> } ></Route>
            <Route path ="topography-coordinate" element = { <TopographyCoordinate/> } ></Route>
            <Route path ="topography-triangle" element = { <TopographyTriangle/> } ></Route>
            <Route path ="topography-intersection" element = { <TopographyIntersection/> } ></Route>
            <Route path ="topography-three" element = { <TopographyThreePoints/> } ></Route>
            <Route path ="topography-area" element = { <TopographyVolume/> } ></Route>

            <Route path ="finance" element = { <FinanceMain/> } ></Route>
            <Route path ="finance-activity" element = { <FinanceActivity/> } ></Route>
            <Route path ="finance-basic" element = { <FinanceBasic/> } ></Route>
            <Route path ="finance-debt" element = { <FinanceDebt/> } ></Route>
            <Route path ="finance-depreciation" element = { <FinanceDepreciation/> } ></Route>
            <Route path ="finance-liquidity" element = { <FinanceLiquidity/> } ></Route>
            <Route path ="finance-markets" element = { <FinanceMarkets/> } ></Route>
           {/**  <Route path ="finance-financial-markets" element = { <FinanceFinancialMarkets/> } ></Route>*/}
            <Route path ="finance-profitability" element = { <FinanceProfitability/> } ></Route>
            <Route path ="finance-banking" element = { <FinanceBanking/> } ></Route>
            <Route path ="finance-corporate" element = { <FinanceCorporate/> } ></Route>
            <Route path ="finance-general" element = { <FinanceGeneral/> } ></Route>
            <Route path ="finance-stocks" element = { <FinanceStocks/> } ></Route>

            <Route path ="statistics" element = { <StatisticsMain/> } ></Route>
            <Route path ="statistics-basic" element = { <StatisticsBasic/> } ></Route>
            <Route path ="statistics-continuous" element = { <StatisticsContinuous/> } ></Route>
            <Route path ="statistics-discrete" element = { <StatisticsDiscrete/> } ></Route>

            <Route path ="science" element = { <ScienceMain/> } ></Route>
            <Route path ="science-math" element = { <ScienceMath/> } ></Route>
            <Route path ="science-physics" element = { <SciencePhysics/> } ></Route>
            <Route path ="science-solar" element = { <ScienceSolar/> } ></Route>

            <Route path ="functions" element = { <FunctionsMain/> } ></Route>

            {/*<Route path ="/disclaimer" element = { <Disclaimers/> } ></Route>
            <Route path ="/products" element = { <Products/> } ></Route>*/}
            <Route path ="/policy" element = { <Policy/> } ></Route>
            <Route path ="/terms" element = { <Term/> } ></Route>
            <Route path ="/cookies" element = { <Cookies/> } ></Route>
            <Route path ="/contact" element = { <Contact/> } ></Route>
            <Route path ="/about" element = { <About/> } ></Route>
            <Route path='*' element={<NotFound />}></Route>

          </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;