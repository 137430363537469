import {AlgebraOneEntry} from "./AlgebraTemplates";
    import {React} from "react";
    import AlgebraAffiliate from "./AlgebraAffiliate";
    import {Helmet} from "react-helmet";


function AlgebraPrime(){
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Prime numbers" />
          <meta property="og:description" content="Check if a number is a prime number."/>
        </Helmet>        
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2  text-sky-500">Prime numbers</h1>
                    <p>
                    A prime number is a number greater than 1 and only divisible by 1 and itself. 
                    Here are a few of the first prime numbers:  2, 3, 5, 7, 11, 13, 17, 19, 23,...
                    Prime numbers have unique properties and play a crucial role in number theory, cryptography, and more.
                    </p><br /><hr /><br />
                        <div>
                            <AlgebraOneEntry endpoint={"prime"}  placeHolder1={"Enter a positive integer"} ></AlgebraOneEntry> 
                        </div>
                        <div className="py-12"> 
                        {/*  <AlgebraAffiliate />  */}
                        </div>

                        <div className="py-24">
                            
                        </div>

                    
                    
                </div>
            </section>
            </>
    )
}

export default AlgebraPrime;