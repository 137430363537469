import {React, useState} from "react";
import {Helmet} from "react-helmet";
import {StatisticsOneEntry, StatisticsTwoEntries, StatisticsThreeEntries} from "../statistics/StatisticsTemplates";
import StatisticsAffiliate from "./StatisticsAffiliate";

function StatisticsBasic(){
    const [selectedText, setSelectedText] = useState('Mean') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Learn the core concepts of basic statistics." />
          <meta property="og:description" content="Covers mean, geometric mean, harmonic mean, median, mode, variance, standard deviation, percentile and zscore."/>
        </Helmet>              

            <section className="text-base-content body-font relative dark:text-white">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <div className="flex flex-col  w-full mb-12">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400 flex justify-center items-center py-2">Basic Statistics</h1>
                    <p>
                    Basic statistics is all about understanding data and making sense of the world through numbers. 
                    Here are some fundamental concepts: <br />
                    &#9724; Mean (Average): Sum of all values divided by the number of values. <br />
                    &#9724; Median: The middle value when all values are sorted in order. 
                    If there's an even number of values, it's the average of the two middle ones. <br />
                    &#9724; Mode: The value that appears most frequently in your dataset. <br />
                    &#9724; Standard Deviation: Measures the amount of variation or dispersion in a set of values. <br />
                    &#9724; Variance: The square of the standard deviation. It tells you how much the values deviate from the mean.  <br />
                    &#9724; Range: The difference between the highest and lowest values in a dataset. <br />
                    &#9724; Probability: The likelihood of an event occurring, ranging from 0 (impossible) to 1 (certain). <br />
                    These basics are the building blocks for more complex analyses and interpretations. 
                    </p><br /><hr /><br />
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="Mean">Mean</option>
                                <option value="GeoMean">Geometric Mean</option>
                                <option value="HarMean">Harmonic Mean</option>
                                <option value="Median">Median</option>
                                <option value="Mode">Mode</option>
                                <option value="PopVar">Population variance</option>
                                <option value="PopStd">Population standard deviation</option>
                                <option value="SampVar">Sample variance</option>
                                <option value="SampStd">Sample standard deviation</option>
                                <option value="NPer">Nth percentile</option>
                                <option value="ZPop">Zscore from population</option>
                                <option value="ZSamp">Zscore from sample</option>
                                <option value="ZMeanStd">Zscore from mean and standard deviation</option>
                            </select>
                        </div>
                        <div>
                            {selectedText==="Mean" && <StatisticsOneEntry endpoint={"mean"}></StatisticsOneEntry>}
                            {selectedText==="GeoMean" && <StatisticsOneEntry endpoint={"geomean"}></StatisticsOneEntry>   }
                            {selectedText==="HarMean" && <StatisticsOneEntry endpoint={"harmean"}></StatisticsOneEntry>   }
                            {selectedText==="Median" && <StatisticsOneEntry endpoint={"median"}></StatisticsOneEntry>}
                            {selectedText==="Mode" && <StatisticsOneEntry endpoint={"mode"}></StatisticsOneEntry>   }
                            {selectedText==="PopVar" && <StatisticsOneEntry endpoint={"popvar"}></StatisticsOneEntry>   }
                            {selectedText==="PopStd" && <StatisticsOneEntry endpoint={"popstd"}></StatisticsOneEntry>}
                            {selectedText==="SampVar" && <StatisticsOneEntry endpoint={"sampvar"}></StatisticsOneEntry>   }
                            {selectedText==="SampStd" && <StatisticsOneEntry endpoint={"sampstd"}></StatisticsOneEntry>   }
                            {selectedText==="NPer" && <StatisticsTwoEntries endpoint={"nper"} inputPlaceHolder={"N"}></StatisticsTwoEntries>}
                            {selectedText==="ZPop" && <StatisticsTwoEntries endpoint={"zpop"} inputPlaceHolder={"Data point"}></StatisticsTwoEntries>}
                            {selectedText==="ZSamp" && <StatisticsTwoEntries endpoint={"zsamp"} inputPlaceHolder={"Data point"}></StatisticsTwoEntries>}
                            {selectedText==="ZMeanStd" && <StatisticsThreeEntries endpoint={"zmeanstd"}></StatisticsThreeEntries>}
                        </div>
                        <div className="py-12"> 
                        {/*  <StatisticsAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>


        </>
    )
}
export default StatisticsBasic;
