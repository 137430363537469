import {TopographyFourEntries} from "./TopographyTemplates";
    import {React} from "react";
    import {Helmet} from "react-helmet";
import gisement_image from "../../assets/images/gisement.png";
import TopographyAffiliate from "./TopographyAffiliate";

function TopographyBearing(){

    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Bearing and distance." />
          <meta property="og:description" content="Calculate the bearing and distance of two points from their coordinates."/>
        </Helmet> 
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400 flex justify-center items-center py-2">Bearing and distance</h1>
                    <p>To determine the bearing and distance between two points, enter the coordinates of the points in the form below 
                        to find the distance and the bearing in gradians from the first point to the second.</p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-12">
                        <div>
                            <TopographyFourEntries endpoint={"gisement"} image={gisement_image} 
                            placeHolder1={"XA"} placeHolder2={"YA"} placeHolder3={"XB"} placeHolder4={"YB"}></TopographyFourEntries>
                        </div>
                        <div className="py-12"> 
                        {/*  <TopographyAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default TopographyBearing;