import {FinanceTwoEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceMarkets(){
    const [selectedText, setSelectedText] = useState('DividendCover') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Financial Markets" />
          <meta property="og:description" 
          content="Calculate dividend cover, dividend per share, dividend yield, earnings per share, payout ratio, price to sales ratio, rate of inflation, etc."/>
        </Helmet>        
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Financial Markets</h1>
                    <p>Financial markets are like bustling marketplaces where assets like stocks, bonds, commodities, and currencies are traded. 
                        They enable companies to raise money by selling shares or issuing bonds, and they offer investors a place to buy and sell investments.
                    In the form below, you can calculate for example dividends, earnings per share, price and earnings ratios and rates.
                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="DividendCover">Dividend Cover </option>
                            <option value="DividendsPerShare">Dividends Per Share (DPS) </option>
                            <option value="DividendYield">Dividend Yield </option>
                            <option value="EarningsPerShare">Earnings Per Share </option>
                            <option value="PayoutRatio">Payout Ratio </option>
                            <option value="Price/EarningstoGrowth">Price/Earnings to Growth (PEG) Ratio </option>
                            <option value="PricetoSales">Price to Sales Ratio </option>

                            <option value="RateofInflation">Rate of Inflation </option>
                            <option value="RealRateof">Real Rate of Return </option>
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="DividendCover" && <> <FinanceTwoEntries endpoint={"markets/dividendcoverfromearningspershareanddividendspershare"} placeHolder1={"Earnings Per Share"}  placeHolder2={"Devidends Per Share"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="DividendsPerShare" && <> <FinanceTwoEntries endpoint={"markets/dividendspersharefromdividendspaidandnumberofshares"} placeHolder1={"Dividends Paid"}  placeHolder2={"Number of Shares"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="DividendYield" && <> <FinanceTwoEntries endpoint={"markets/dividendyieldfromannualdividendpershareandpricepershare"} placeHolder1={"Annual Dividend Per Share"}  placeHolder2={"Price Per Share"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="EarningsPerShare" && <> <FinanceTwoEntries endpoint={"markets/earningspersharefromnetearningsandnumberofshares"} placeHolder1={"Net Earning"}  placeHolder2={"Number of Shares"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="PayoutRatio" && <> <FinanceTwoEntries endpoint={"markets/payoutratiofromdividendsandearnings"} placeHolder1={"Dividends"}  placeHolder2={"Earnings"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="Price/EarningstoGrowth" && <> <FinanceTwoEntries endpoint={"markets/price-earningstogrowthratiofrompriceperearningsandannualepsgrowth"} placeHolder1={"Price Per Earnings"}  placeHolder2={"Annual EPS Growth"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="PricetoSales" && <> <FinanceTwoEntries endpoint={"markets/pricetosalesratiofrompricepershareandrevenuepershare"} placeHolder1={"Price Per Share"}  placeHolder2={"Revenue Per Share"} ></FinanceTwoEntries>   </>}   

                        {selectedText==="RateofInflation" && <> <FinanceTwoEntries endpoint={"financialmarkets/rateofinflationfrominitialconsumerpriceindexandendingconsumerpriceindex"} placeHolder1={"Initial consumer price index"}  placeHolder2={"Ending consumer price index"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="RealRateof" && <> <FinanceTwoEntries endpoint={"financialmarkets/realrateofreturnfromnominalrateandinflationrate"} placeHolder1={"Nominal rate"}  placeHolder2={"Inflation rate"} ></FinanceTwoEntries>   </>}   

                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceMarkets;