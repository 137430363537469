import {FinanceTwoEntries,FinanceThreeEntries,FinanceFourEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceDepreciation(){
    const [selectedText, setSelectedText] = useState('BookValue') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Depreciation" />
          <meta property="og:description" 
          content="Calculate book value, declining balance, units of production, straight line method, etc."/>
        </Helmet>         
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Depreciation</h1>
                    <p>
                    Depreciation is all about spreading the cost of an asset over its useful life. 
                    It's an essential concept in accounting, helping businesses recognize the expense of tangible assets over time.
                    In the form below, you can calculate for instance book value, declining balance, units of production and straight line method. 
                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="BookValue">Book Value </option>
                            <option value="DecliningBalance">Declining Balance </option>
                            <option value="UnitsOfProduction">Units Of Production </option>
                            <option value="StraightLineMethod">Straight Line Method </option>
                               
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="BookValue" && <> <FinanceTwoEntries endpoint={"depreciation/bookvaluefromacquisitioncostanddepreciation"} placeHolder1={"Acquisition Cost"}  placeHolder2={"Depreciation"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="DecliningBalance" && <> <FinanceTwoEntries endpoint={"depreciation/decliningbalancefromdepreciationrateandbookvalueatbeginningofyear"} placeHolder1={"Depreciation Rate"}  placeHolder2={"Book Value at Beginning of Year"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="UnitsOfProduction" && <> <FinanceFourEntries endpoint={"depreciation/unitsofproductionfromcostofassetresidualvalueestimatedtotalproductionandactualproduction"} placeHolder1={"Cost of Asset"}  placeHolder2={"Residual Value"} placeHolder3={"Estimated Total Production"} placeHolder4={"Actual Production"}></FinanceFourEntries>   </>}    
                        {selectedText==="StraightLineMethod" && <> <FinanceThreeEntries endpoint={"depreciation/straightlinemethodfromcostoffixedassetresidualvalueandusefullifeofasset"} placeHolder1={"Cost of Fixed Asset"}  placeHolder2={"Residual Value"} placeHolder3={"Useful Life of Asset"}></FinanceThreeEntries>   </>}   



                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceDepreciation;