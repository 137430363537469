import {React, useState} from "react";


function TopographyTwoEntries({endpoint,image, placeHolder1, placeHolder2}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;
        const item2 = searchItem2;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/topography/${endpoint}/${item1}/${item2}`;
   
        if(item1 !== '' && item2 !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
            <div className="card shadow w-full dark:bg-red-200">
                <div className="card-body">
                    <figure>
                        <img src={image} alt="#" />
                    </figure>
                </div>

            </div>
        </div>
    )
}

function TopographyThreeEntries({endpoint,image, placeHolder1, placeHolder2, placeHolder3}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;
        const item2 = searchItem2;
        const item3 = searchItem3;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/topography/${endpoint}/${item1}/${item2}/${item3}`;
   
        if(item1 !== '' && item2 !== '' && item3 !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder3} className="input input-bordered w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
            <div className="card shadow w-full dark:bg-red-200">
                <div className="card-body">
                    <figure>
                        <img src={image} alt="#" />
                    </figure>
                </div>

            </div>
        </div>
    )
}

function TopographyFourEntries({endpoint,image, placeHolder1, placeHolder2, placeHolder3, placeHolder4}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')
    const [searchItem4, setSearchItem4] = useState('')
    
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;
        const item2 = searchItem2;
        const item3 = searchItem3;
        const item4 = searchItem4;
        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/topography/${endpoint}/${item1}/${item2}/${item3}/${item4}`;
   
        if(item1 !== '' && item2 !== '' && item3 !== '' && item4 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder3} className="input input-bordered w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder4} className="input input-bordered w-full"  value={searchItem4} onChange={event => setSearchItem4(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-purple-600 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
            <div className="card shadow w-full dark:bg-red-200">
                <div className="card-body">
                    <figure>
                        <img src={image} alt="#" />
                    </figure>
                </div>

            </div>
        </div>
    )
}


function TopographyFiveEntries({endpoint,image, placeHolder1, placeHolder2, placeHolder3, placeHolder4, placeHolder5}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')
    const [searchItem4, setSearchItem4] = useState('')
    const [searchItem5, setSearchItem5] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;
        const item2 = searchItem2;
        const item3 = searchItem3;
        const item4 = searchItem4;
        const item5 = searchItem5;
        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/topography/${endpoint}/${item1}/${item2}/${item3}/${item4}/${item5}`;
   
        if(item1 !== '' && item2 !== '' && item3 !== '' && item4 !== '' && item5 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-1">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder3} className="input input-bordered w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder4} className="input input-bordered w-full"  value={searchItem4} onChange={event => setSearchItem4(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder5} className="input input-bordered w-full"  value={searchItem5} onChange={event => setSearchItem5(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
            <div className="card shadow w-full dark:bg-red-200">
                <div className="card-body">
                    <figure>
                        <img src={image} alt="#" />
                    </figure>
                </div>

            </div>
        </div>
    )
}


function TopographySixEntries({endpoint,image, placeHolder1, placeHolder2, placeHolder3, placeHolder4, placeHolder5, placeHolder6}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')
    const [searchItem4, setSearchItem4] = useState('')
    const [searchItem5, setSearchItem5] = useState('')
    const [searchItem6, setSearchItem6] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;
        const item2 = searchItem2;
        const item3 = searchItem3;
        const item4 = searchItem4;
        const item5 = searchItem5;
        const item6 = searchItem6;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/topography/${endpoint}/${item1}/${item2}/${item3}/${item4}/${item5}/${item6}`;
   
        if(item1 !== '' && item2 !== '' && item3 !== '' && item4 !== '' && item5 !== '' && item6 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-1">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered input-sm w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered input-sm w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder3} className="input input-bordered input-sm w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder4} className="input input-bordered input-sm w-full"  value={searchItem4} onChange={event => setSearchItem4(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder5} className="input input-bordered input-sm w-full"  value={searchItem5} onChange={event => setSearchItem5(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder6} className="input input-bordered input-sm w-full"  value={searchItem6} onChange={event => setSearchItem6(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-orange-700 hover:bg-orange-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
            <div className="card shadow w-full dark:bg-red-200">
                <div className="card-body">
                    <figure>
                        <img src={image} alt="#" />
                    </figure>
                </div>

            </div>
        </div>
    )
}


function TopographyEightEntries({endpoint,image, placeHolder1, placeHolder2, placeHolder3, placeHolder4, placeHolder5, placeHolder6, placeHolder7, placeHolder8}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')
    const [searchItem4, setSearchItem4] = useState('')
    const [searchItem5, setSearchItem5] = useState('')
    const [searchItem6, setSearchItem6] = useState('')
    const [searchItem7, setSearchItem7] = useState('')
    const [searchItem8, setSearchItem8] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;
        const item2 = searchItem2;
        const item3 = searchItem3;
        const item4 = searchItem4;
        const item5 = searchItem5;
        const item6 = searchItem6;
        const item7 = searchItem7;
        const item8 = searchItem8;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/topography/${endpoint}/${item1}/${item2}/${item3}/${item4}/${item5}/${item6}/${item7}/${item8}`;
   
        if(item1 !== '' && item2 !== '' && item3 !== '' && item4 !== '' && item5 !== '' && item6 !== ''&& item7 !== '' && item8 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-1">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered input-sm w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered input-sm w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder3} className="input input-bordered input-sm w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder4} className="input input-bordered input-sm w-full"  value={searchItem4} onChange={event => setSearchItem4(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder5} className="input input-bordered input-sm w-full"  value={searchItem5} onChange={event => setSearchItem5(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder6} className="input input-bordered input-sm w-full"  value={searchItem6} onChange={event => setSearchItem6(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder7} className="input input-bordered input-sm w-full"  value={searchItem7} onChange={event => setSearchItem7(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder8} className="input input-bordered input-sm w-full"  value={searchItem8} onChange={event => setSearchItem8(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
            <div className="card shadow w-full dark:bg-red-200">
                <div className="card-body">
                    <figure>
                        <img src={image} alt="#" />
                    </figure>
                </div>

            </div>
        </div>
    )
}


function TopographyNineEntries({endpoint,image, placeHolder1, placeHolder2, placeHolder3, placeHolder4, placeHolder5, placeHolder6, placeHolder7, placeHolder8, placeHolder9}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')
    const [searchItem4, setSearchItem4] = useState('')
    const [searchItem5, setSearchItem5] = useState('')
    const [searchItem6, setSearchItem6] = useState('')
    const [searchItem7, setSearchItem7] = useState('')
    const [searchItem8, setSearchItem8] = useState('')
    const [searchItem9, setSearchItem9] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;
        const item2 = searchItem2;
        const item3 = searchItem3;
        const item4 = searchItem4;
        const item5 = searchItem5;
        const item6 = searchItem6;
        const item7 = searchItem7;
        const item8 = searchItem8;
        const item9 = searchItem9;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/topography/${endpoint}/${item1}/${item2}/${item3}/${item4}/${item5}/${item6}/${item7}/${item8}/${item9}`;
   
        if(item1 !== '' && item2 !== '' && item3 !== '' && item4 !== '' && item5 !== '' && item6 !== ''&& item7 !== '' && item8 !== '' && item9 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-1">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered input-sm w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered input-sm w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder3} className="input input-bordered input-sm w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder4} className="input input-bordered input-sm w-full"  value={searchItem4} onChange={event => setSearchItem4(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder5} className="input input-bordered input-sm w-full"  value={searchItem5} onChange={event => setSearchItem5(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder6} className="input input-bordered input-sm w-full"  value={searchItem6} onChange={event => setSearchItem6(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder7} className="input input-bordered input-sm w-full"  value={searchItem7} onChange={event => setSearchItem7(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder8} className="input input-bordered input-sm w-full"  value={searchItem8} onChange={event => setSearchItem8(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder9} className="input input-bordered input-sm w-full"  value={searchItem9} onChange={event => setSearchItem9(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-purple-600 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
            <div className="card shadow w-full dark:bg-red-200">
                <div className="card-body">
                    <figure>
                        <img src={image} alt="#" />
                    </figure>
                </div>

            </div>
        </div>
    )
}

export { TopographyTwoEntries, TopographyThreeEntries,TopographyFourEntries,TopographyFiveEntries,TopographySixEntries,TopographyEightEntries,TopographyNineEntries};