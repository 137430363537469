import { TopographyTwoEntries} from "./TopographyTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import sector_image from "../../assets/images/circlesector.png";
    import segment_image from "../../assets/images/circlesegment.png";
    import para_image from "../../assets/images/parasegment.png";
    import TopographyAffiliate from "./TopographyAffiliate";

function TopographyCircular(){
    const [selectedText, setSelectedText] = useState('circlesector') //default selected value
    return(
        <>
         <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Circular segments." />
          <meta property="og:description" content="Calculate the areas of circular sectors, circular segments and parabolic segments."/>
        </Helmet>        
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400  flex justify-center items-center py-2">Circular segments</h1>
                    <p>A circular sector or segment is like a slice of a pie. It's part of a circle enclosed by two radii and an arc. 
                        In the form below you can calculate the area of a circular sector, segment and a parabolic segment. 
                        </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-12">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="circlesector">Circular sector</option>
                                <option value="circlesegment">Circular segment</option>
                                <option value="parabolicsegment">Parabolic segment</option>
                            </select>
                        </div>
                        <div>
                            {selectedText==="circlesector" && <TopographyTwoEntries endpoint={"segment/circlesector"} image={sector_image} placeHolder1={"Angle A in gradians"} placeHolder2="Radius R"></TopographyTwoEntries>}
                            {selectedText==="circlesegment" && <TopographyTwoEntries endpoint={"segment/circlesegment"} image={segment_image} placeHolder1={"Angle A in gradians"} placeHolder2="Radius R"></TopographyTwoEntries>}
                            {selectedText==="parabolicsegment" && <TopographyTwoEntries endpoint={"segment/parabolicsegment"} image={para_image} placeHolder1={"Distance a"} placeHolder2="Distance b"></TopographyTwoEntries>}
                           
                        </div>
                        <div className="py-12"> 
                        {/*  <TopographyAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default TopographyCircular;