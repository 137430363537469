import React from "react";
import {Helmet} from "react-helmet";
import polynomial_image from "../../assets/images/gradient-numerology-background_23-2150066816.avif";
import complex_image from "../../assets/images/gradient-numerology-background_23-2150051046.avif";
import inequality_image from "../../assets/images/top-view-balance-cubes-blue-background_23-2148879422.avif";
import slope_image from "../../assets/images/gradient-abstract-logo_23-2150689661.png";
import fibo_image from "../../assets/images/golden-ratio_98292-4405.avif";
import prime_image from "../../assets/images/gradient-numerology-background_23-2150140099.jpg";


function AlgebraMain(){
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="College Algebra" />
          <meta property="og:description" content="Solve equations, inequalities. Find slopes, equations and interceps of lines."/>
        </Helmet>          
            {/**Description */}
            <section className="text-gray-600 body-font relative">
                <div className="container px-5 py-2 mx-auto">
                    <div className="flex flex-col text-justify w-full mb-12">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mx-auto mb-4 text-sky-500">Algebra</h1>
                        <p className="text-black-500 dark:text-white">
                            College Algebra is a math solver and a course assistant that every student will need no matter the degree he is pursuing at university.
                            College algebra is a core requirement of most majors at universities in the United States and worldwide.
                            The application contains all the significant chapters a student has to deal with when taking college algebra courses.
                            The application will help deal with: 
                        </p>
                        <ul className="space-y-4 py-4 px-12">
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Polynomials</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Complex numbers</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Inequalities</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Slopes and Intercepts</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Equation of lines</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Prime numbers</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white">Fibonacci numbers</p>
                            </li>
                        </ul>
                        <div className=" dark:text-white">
                            <span>The native Application can be downloaded on the different applications stores: </span>
                            <a className="btn btn-ghost btn-circle btn-sm mr-4" href="https://play.google.com/store/apps/details?id=tntechnology.app.college_algebra" target={"_blank"} rel="noopener noreferrer">
                                <svg width="20px" height="20px" viewBox="19.932999999999996 68.509 228.155 228.155" xmlns="http://www.w3.org/2000/svg"><path d="M101.885 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24z" fill="#a4c639"/><path d="M69.374 133.645c-.047.54-.088 1.086-.088 1.638v92.557c0 9.954 7.879 17.973 17.66 17.973h94.124c9.782 0 17.661-8.02 17.661-17.973v-92.557c0-.552-.02-1.1-.066-1.638H69.374z" fill="#a4c639"/><path d="M166.133 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24zM46.405 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c-.001-7.865 6.375-14.242 14.241-14.242zM221.614 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c0-7.865 6.376-14.242 14.241-14.242zM69.79 127.565c.396-28.43 25.21-51.74 57.062-54.812h14.312c31.854 3.073 56.666 26.384 57.062 54.812H69.79z" fill="#a4c639"/><path d="M74.743 70.009l15.022 26.02M193.276 70.009l-15.023 26.02" fill="none" stroke="#a4c639" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/><path d="M114.878 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04zM169.874 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04z" fill="#ffffff"/></svg>
                                </a>
                                <a className="btn btn-ghost btn-circle btn-sm" href="https://www.microsoft.com/store/apps/9P5VCP3VN76Q" target={"_blank"} rel="noopener noreferrer">
                                <svg width="20px" height="20px" viewBox="-0.5 0 257 257" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M0 36.357L104.62 22.11l.045 100.914-104.57.595L0 36.358zm104.57 98.293l.08 101.002L.081 221.275l-.006-87.302 104.494.677zm12.682-114.405L255.968 0v121.74l-138.716 1.1V20.246zM256 135.6l-.033 121.191-138.716-19.578-.194-101.84L256 135.6z" fill="#00ADEF"/></svg>
                                </a> . <span  className="text-black-500 dark:text-white">You will find below the sub-topics of College Algebra. Please select one of the options to proceed further.</span> 
                            </div>
                    </div>
                </div>
            </section>
            {/**Topics */}
            <section className="text-gray-600 body-font  dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-5">
                        <div className="p-4 lg:w-1/3">
                            {/**Polynomials*/}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                    <img src={polynomial_image} alt="polynomial" />
                                </figure>
                                <div className="card-body flex items-center  dark:text-white">
                                    <h2 className="card-title"> Polynomials</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Factor, expand, add, multiply ... polynomials
                                    </p>
                                    <a className="btn bg-blue-500 hover:bg-blue-800 text-white btn-sm" href="/algebra-polynomial">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">
                            {/**Complex */}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                    <img src={complex_image} alt="complex" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Complex Numbers</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Apply various functions to complex numbers.
                                    </p>
                                    <a className="btn bg-purple-500 hover:bg-purple-800 text-white btn-sm" href="/algebra-complex">Access</a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 lg:w-1/3">
                            {/**Inequalities*/}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                    <img src={inequality_image} alt="ineq" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Inequalities</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Solve inequalities and double inequalities.
                                    </p>
                                    <a className="btn bg-red-400 hover:bg-red-800 text-white btn-sm" href="/algebra-inequality">Access</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-gray-600 body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-5">
                        <div className="p-4 lg:w-1/3">
                            {/**Slope*/}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                    <img src={slope_image} height={42} alt="slope" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Slope and intercepts.</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Find the slopes, intercepts and equations of lines.
                                    </p>
                                    <a className="btn bg-red-500 hover:bg-red-800 text-white btn-sm" href="/algebra-slope">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">
                            {/**fibo */}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                    <img src={fibo_image} alt="fibo" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Fibonacci number</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Given an integer n, find the n<sup>th</sup> Fibonacci number.
                                    </p>
                                    <a className="btn bg-blue-500 hover:bg-blue-800 text-white btn-sm" href="/algebra-fibonacci">Access</a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 lg:w-1/3">
                            {/**Inequalities*/}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                    <img src={prime_image} alt="prime" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Prime number</h2>
                                    <p className="text-black-500 dark:text-white">
                                    Check if a number is a prime number.
                                    </p>
                                    <a className="btn bg-blue-400 hover:bg-blue-800 text-white btn-sm" href="/algebra-prime">Access</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default AlgebraMain;
