import {FinanceOneEntry, FinanceTwoEntries,FinanceThreeEntries,FinanceFourEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import {Helmet} from "react-helmet";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceStocks(){
    const [selectedText, setSelectedText] = useState('BidAskSpread') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Stocks and Bonds" />
          <meta property="og:description" 
          content="Calculate bid ask spread, book value per share, capital asset pricing model, current yield, diluted earnings per share, equity multiplier from assets, estimated earnings, risk premium, growth rate, stock present value, total stock return, etc."/>
        </Helmet>        
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Stocks and Bonds</h1>
                    <p>Stocks and bonds are fundamental components of the financial markets, each serving different purposes:
Stocks represent ownership in a company while bonds represent a loan made by an investor to a borrower (typically corporate or governmental).
        In the next form, you can calculate dividends, earnings, equity, holding period and total stock returns.
                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="BidAskSpread">Bid Ask Spread </option>
                            <option value="BondEquivalentYield">Bond Equivalent Yield </option>
                            <option value="BookValueper">Book Value per Share </option>
                            <option value="CapitalAssetPricing">Capital Asset Pricing Model </option>
                            <option value="CapitalGainsYield">Capital Gains Yield </option>
                            <option value="CurrentYield">Current Yield </option>
                            <option value="DilutedEarningsper">Diluted Earnings per Share </option>
                            <option value="DividendsPayoutRatio">Dividends Payout Ratio </option>
                            <option value="DividendYield">Dividend Yield </option>
                            <option value="DividendsperShare">Dividends per Share </option>
                            <option value="EarningsperShare">Earnings per Share </option>
                            <option value="EquityMultiplier1">Equity Multiplier From Assets</option>
                            <option value="EquityMultiplier2">Equity Multiplier From Ratio</option>
                            <option value="EstimatedEarnings">Estimated Earnings </option>
                            <option value="EstimatedEarningswith">Estimated Earnings with Profit Margin </option>
                            <option value="HoldingPeriodReturn1">Holding Period Return From Rate </option>
                            <option value="HoldingPeriodReturn2">Holding Period Return From Earnings </option>
                            <option value="NetAssetValue">Net Asset Value </option>
                            <option value="PreferredStockValue">Preferred Stock Value </option>
                            <option value="RateofReturn">Rate of Return </option>
                            <option value="PricetoBook">Price to Book Value Ratio </option>
                            <option value="PricetoEarnings">Price to Earnings Ratio </option>
                            <option value="PricetoSales">Price to Sales Ratio </option>
                            <option value="RiskPremium">Risk Premium </option>
                            <option value="StockPresentValue1">Stock Present Value with Constant Growth </option>
                            <option value="GrowthRate">Growth Rate </option>
                            <option value="RequiredRateof">Required Rate of Return </option>
                            <option value="StockPresentValue2">Stock Present Value with Zero Growth </option>
                            <option value="TaxEquivalentYield">Tax Equivalent Yield </option>
                            <option value="TotalStockReturn1">Total Stock Return Percentage </option>
                            <option value="TotalStockReturn2">Total Stock Return Cash </option>
                            <option value="TotalStockReturn3">Total Stock Return by Yields </option>
                            <option value="ApproximateYieldto">Approximate Yield to Maturity </option>
                            <option value="ZeroCouponBond1">Zero Coupon Bond Value </option>
                            <option value="ZeroCouponBond2">Zero Coupon Bond Yield </option>
                               
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="BidAskSpread" && <> <FinanceTwoEntries endpoint={"stocks/bidaskspreadfrombidandask"} placeHolder1={"Bid"}  placeHolder2={"Ask"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="BondEquivalentYield" && <> <FinanceThreeEntries endpoint={"stocks/bondequivalentyieldfromfacevaluebondpriceanddaystomaturity"} placeHolder1={"Face Value"}  placeHolder2={"Bond Price"} placeHolder3={"Days to Maturity"}></FinanceThreeEntries>   </>}    
                        {selectedText==="BookValueper" && <> <FinanceTwoEntries endpoint={"stocks/bookvaluepersharefromtotalcommonstockholdersequityandnumberofcommonshares"} placeHolder1={"Total Common Stockholders Equity"}  placeHolder2={"Number of Common Shares"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="CapitalAssetPricing" && <> <FinanceThreeEntries endpoint={"stocks/capitalassetpricingmodelfromrisk-freeratebetaandreturnonthemarket"} placeHolder1={"Risk-Free Rate"}  placeHolder2={"Beta"} placeHolder3={"Return on the Market"}></FinanceThreeEntries>   </>}    
                        {selectedText==="CapitalGainsYield" && <> <FinanceTwoEntries endpoint={"stocks/capitalgainsyieldfrominitialstockpriceandendingstockprice"} placeHolder1={"Initial Stock Price"}  placeHolder2={"Ending Stock Price"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="CurrentYield" && <> <FinanceTwoEntries endpoint={"stocks/currentyieldfromannualcouponsandcurrentbondprice"} placeHolder1={"Annual Coupons"}  placeHolder2={"Current Bond Price"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="DilutedEarningsper" && <> <FinanceThreeEntries endpoint={"stocks/dilutedearningspersharefromnetincomeaveragesharesandotherconvertibleinstruments"} placeHolder1={"Net Income"}  placeHolder2={"Average Shares"} placeHolder3={"Other Convertible Instruments"}></FinanceThreeEntries>   </>}    
                        {selectedText==="DividendsPayoutRatio" && <> <FinanceTwoEntries endpoint={"stocks/dividendspayoutratiofromdividendsandnetincome"} placeHolder1={"Dividends"}  placeHolder2={"Net Income"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="DividendYield" && <> <FinanceTwoEntries endpoint={"stocks/dividendyieldfromdividendsfortheperiodandinitialpricefortheperiod"} placeHolder1={"Dividends for the Period"}  placeHolder2={"Initial Price for the Perio"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="DividendsperShare" && <> <FinanceTwoEntries endpoint={"stocks/dividendspersharefromdividendsandnumberofshares"} placeHolder1={"Dividend"}  placeHolder2={"Number of Shares"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="EarningsperShare" && <> <FinanceTwoEntries endpoint={"stocks/earningspersharefromnetincomeandweightedaverageoutstandingshares"} placeHolder1={"Net Income"}  placeHolder2={"Weighted Average Outstanding Shares"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="EquityMultiplier1" && <> <FinanceTwoEntries endpoint={"stocks/equitymultiplierfromtotalassetsandstockholdersequity"} placeHolder1={"Total Assets"}  placeHolder2={"Stockholders Equity"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="EquityMultiplier2" && <> <FinanceOneEntry endpoint={"stocks/equitymultiplierfromequityratio"} placeHolder1={"Equity Ratio"}  ></FinanceOneEntry>   </>}    
                        {selectedText==="EstimatedEarnings" && <> <FinanceTwoEntries endpoint={"stocks/estimatedearningsfromforecastedsalesandforecastedexpenses"} placeHolder1={"Forecasted Sales"}  placeHolder2={"Forecasted Expenses"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="EstimatedEarningswith" && <> <FinanceTwoEntries endpoint={"stocks/estimatedearningswithprofitmarginfromprojectedsalesandprojectednetprofitmargin"} placeHolder1={"Projected Sales"}  placeHolder2={"Projected Net Profit Margin"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="HoldingPeriodReturn1" && <> <FinanceTwoEntries endpoint={"stocks/holdingperiodreturnfromperiodicrateandnumberofperiods"} placeHolder1={"Periodic Rate"}  placeHolder2={"Number of Periods"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="HoldingPeriodReturn2" && <> <FinanceThreeEntries endpoint={"stocks/holdingperiodreturnfromearningsassetappreciationandinitialinvestment"} placeHolder1={"Earnings"}  placeHolder2={"Asset Appreciation"} placeHolder3={"Initial Investment"}></FinanceThreeEntries>   </>}    
                        {selectedText==="NetAssetValue" && <> <FinanceThreeEntries endpoint={"stocks/netassetvaluefromfundassetsfundliabilitiesandoutstandingshares"} placeHolder1={"Fund Assets"}  placeHolder2={"Fund Liabilities"} placeHolder3={"Outstanding Shares"}></FinanceThreeEntries>   </>}    
                        {selectedText==="PreferredStockValue" && <> <FinanceTwoEntries endpoint={"stocks/preferredstockvaluefromdividendanddiscountrate"} placeHolder1={"Dividend"}  placeHolder2={"Discount Rate"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="RateofReturn" && <> <FinanceTwoEntries endpoint={"stocks/rateofreturnfromdividendandprice"} placeHolder1={"Dividend"}  placeHolder2={"Price"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="PricetoBook" && <> <FinanceTwoEntries endpoint={"stocks/pricetobookvalueratiofrommarketpricepershareandbookvaluepershare"} placeHolder1={"Market Price per Share"}  placeHolder2={"Book Value per Share"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="PricetoEarnings" && <> <FinanceTwoEntries endpoint={"stocks/pricetoearningsratiofrompricepershareandearningspershare"} placeHolder1={"Price per Share"}  placeHolder2={"Earnings per Share"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="PricetoSales" && <> <FinanceTwoEntries endpoint={"stocks/pricetosalesratiofrompricepershareandsalespershare"} placeHolder1={"Price per Share"}  placeHolder2={"Sales per Share"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="RiskPremium" && <> <FinanceTwoEntries endpoint={"stocks/riskpremiumfromassetorinvestmentreturnandriskfreereturn"} placeHolder1={"Asset or Investment Return"}  placeHolder2={"Risk-Free Return"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="StockPresentValue1" && <> <FinanceThreeEntries endpoint={"stocks/stockpresentvaluewithconstantgrowthfromestimateddividendsfornextperiodrequiredrateofreturnandgrowthrate"} placeHolder1={"Estimated Dividends for Next Period"}  placeHolder2={"Required Rate Of Return"} placeHolder3={"Growth Rate"}></FinanceThreeEntries>   </>}    
                        {selectedText==="GrowthRate" && <> <FinanceTwoEntries endpoint={"stocks/growthratefromretentionrateandreturnonequity"} placeHolder1={"Retention Rate"}  placeHolder2={"Return on Equity"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="RequiredRateof" && <> <FinanceTwoEntries endpoint={"stocks/requiredrateofreturnfromdividendyieldandgrowthrate"} placeHolder1={"Dividend Yield"}  placeHolder2={"Growth Rate"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="StockPresentValue2" && <> <FinanceTwoEntries endpoint={"stocks/stockpresentvaluewithzerogrowthfromdividendsperperiodandrequiredrateofreturn"} placeHolder1={"Dividends per Period"}  placeHolder2={"Required Rate of Return"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="TaxEquivalentYield" && <> <FinanceTwoEntries endpoint={"stocks/taxequivalentyieldfromtax-freeyieldandtaxrate"} placeHolder1={"Tax-Free Yield"}  placeHolder2={"Tax Rate"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="TotalStockReturn1" && <> <FinanceThreeEntries endpoint={"stocks/totalstockreturnpercentagefrominitialstockpriceendingstockpriceanddividends"} placeHolder1={"Initial Stock Price"}  placeHolder2={"Ending Stock Price"} placeHolder3={"Dividends"}></FinanceThreeEntries>   </>}    
                        {selectedText==="TotalStockReturn2" && <> <FinanceThreeEntries endpoint={"stocks/totalstockreturncashfrominitialstockpriceendingstockpriceanddividends"} placeHolder1={"Initial Stock Price"}  placeHolder2={"Ending Stock Price"} placeHolder3={"Dividends"}></FinanceThreeEntries>   </>}    
                        {selectedText==="TotalStockReturn3" && <> <FinanceTwoEntries endpoint={"stocks/totalstockreturnbyyieldsfromdividendyieldandcapitalgainsyield"} placeHolder1={"Dividend Yield"}  placeHolder2={"Capital Gains Yield"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ApproximateYieldto" && <> <FinanceFourEntries endpoint={"stocks/approximateyieldtomaturityfromcoupon/interestpaymentfacevaluepriceandyearstomaturity"} placeHolder1={"Coupon/Interest Payment"}  placeHolder2={"Face Value"} placeHolder3={"Price"} placeHolder4={"Years to Maturity"}></FinanceFourEntries>   </>}    
                        {selectedText==="ZeroCouponBond1" && <> <FinanceThreeEntries endpoint={"stocks/zerocouponbondvaluefromfacevaluerate/yieldandtimetomaturity"} placeHolder1={"Face Value"}  placeHolder2={"Rate (Yield)"} placeHolder3={"Time to Maturity"}></FinanceThreeEntries>   </>}    
                        {selectedText==="ZeroCouponBond2" && <> <FinanceThreeEntries endpoint={"stocks/zerocouponbondyieldfromfacevaluepresentvalueandtimetomaturity"} placeHolder1={"Face Value"}  placeHolder2={"Present Value"} placeHolder3={"Time to Maturity"}></FinanceThreeEntries>   </>}     



                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceStocks;