import {React, useState} from "react";
import {Helmet} from "react-helmet";
import sphere_image from "../../assets/images/sphere.png";
import icosahedron_image from "../../assets/images/icosahedron.png";
import pyramid_image from "../../assets/images/pyramid.png";
import cone_image from "../../assets/images/cone.png";
import box_image from "../../assets/images/box.png";
import spheroid_image from "../../assets/images/spheroid.png";
import ellipsoid_image from "../../assets/images/Ellipsoid.png";
import cylinder_image from "../../assets/images/cylinder.png";
import {SurfaceOneEntry, SurfaceTwoEntries, SurfaceThreeEntries} from "../surface/SurfaceTemplates";

function SurfaceVolume(){
    const [selectedText, setSelectedText] = useState('spherevolume') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="Calculate volume of geometric figures" />
          <meta property="og:title" content="Areas and volumes." />
          <meta property="og:description" content="Calculate volume of sphere, icosahedron, pyramid, cylinder, cone, spheroid, box and ellipsoid."/>
        </Helmet> 
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400  flex justify-center items-center py-2">Volumes of geometric figures</h1>
                    <p>
                    The volume of a geometric figure is a measure of the space occupied by an object in three-dimensional space1. 
                    Since volume is a three-dimensional measure, we use cubic units to measure it. 
                    The volume formula depends on the shape of the figure and its different dimensions. 
                    The formulas for some common shapes are shown below: <br />
                    &#9724; Cube: V = s^3 <br />
                    &#9724; Rectangular prism: V = lwh <br />
                    &#9724; Sphere: V = 4/3πr^3 <br />
                    &#9724; Cylinder: V = πr^2h <br />
                    &#9724; Cone: V = 1/3πr^2h 
                    </p><br /><hr /><br />
                    <div className="flex flex-col text-center w-full mb-12">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="spherevolume">Volume of a sphere</option>
                                <option value="icosahedronvolume">Volume of an icosahedron</option>
                                <option value="pyramidvolume">Volume of a pyramid</option>
                                <option value="cylindervolume">Volume of a cylinder</option>
                                <option value="conevolume">Volume of a cone</option>
                                <option value="spheroidvolume">Volume of a spheroid</option>
                                <option value="boxvolume">Volume of a box</option>
                                <option value="ellipsoidvolume">Volume of an ellipsoid</option>

                            </select>
                        </div>
                        <div>
                            {selectedText==="spherevolume" && <SurfaceOneEntry endpoint={"spherevolume"} image={sphere_image} placeHolder1={"Radius R"}></SurfaceOneEntry>}
                            {selectedText==="icosahedronvolume" && <SurfaceOneEntry endpoint={"icosahedronvolume"} image={icosahedron_image} placeHolder1={"Side L"}></SurfaceOneEntry>}

                            {selectedText==="pyramidvolume" && <SurfaceTwoEntries endpoint={"pyramidvolume"} image={pyramid_image} placeHolder1={"Base area B"} placeHolder2={"Height H"}></SurfaceTwoEntries>}
                            {selectedText==="cylindervolume" && <SurfaceTwoEntries endpoint={"cylindervolume"} image={cylinder_image} placeHolder1={"Radius R"} placeHolder2={"Height H"}></SurfaceTwoEntries>}
                            {selectedText==="conevolume" && <SurfaceTwoEntries endpoint={"conevolume"} image={cone_image} placeHolder1={"Radius R"} placeHolder2={"Height H"}></SurfaceTwoEntries>}
                            {selectedText==="spheroidvolume" && <SurfaceTwoEntries endpoint={"spheroidvolume"} image={spheroid_image} placeHolder1={"Radius a"} placeHolder2={"Height c"}></SurfaceTwoEntries>}
                            {selectedText==="boxvolume" && <SurfaceThreeEntries endpoint={"boxvolume"} image={box_image} placeHolder1={"Length L"} placeHolder2={"Width W"} placeHolder3={"Height H"}></SurfaceThreeEntries>}
                            {selectedText==="ellipsoidvolume" && <SurfaceThreeEntries endpoint={"ellipsoidvolume"} image={ellipsoid_image} placeHolder1={"Semi-axis a"} placeHolder2={"Semi-axis b"} placeHolder3={"Semi-axis c"}></SurfaceThreeEntries>}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default SurfaceVolume;