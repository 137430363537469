import {React, useState} from "react";
import {Helmet} from "react-helmet";
import {DistributionTwoEntries, DistributionThreeEntries, DistributionFourEntries} from "../statistics/StatisticsTemplates";
import StatisticsAffiliate from "./StatisticsAffiliate";

function StatisticsDiscrete(){
    const [selectedText, setSelectedText] = useState('dis') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Learn the core concepts of discrete distributions." />
          <meta property="og:description" 
          content="Covers discrete uniform, bernoulli, binomial, negative binomial, geometric, hypergeometric, poisson, conway-maxwell-poisson, and zipf distributions."/>
        </Helmet>             

            <section className="text-base-content body-font relative dark:text-white">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <div className="flex flex-col w-full mb-12">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400 flex justify-center items-center py-2">Discrete Distributions</h1>
                    <p>
                    Discrete probability distributions describe scenarios where outcomes are counted in distinct, separate values. 
                    Here are a few key ones: <br />

                    &#9724; Binomial Distribution <br />
                    &#9724; Poisson Distribution <br />
                    &#9724; Geometric Distribution <br />
                    &#9724; Negative Binomial Distribution <br />
                    &#9724; Hypergeometric Distribution <br />
Each of these distributions is tailored to specific types of problems involving discrete data.
                    </p><br /><hr /><br />
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                
                            <option value="dis">Discrete Uniform</option>
                            <option value="ber">Bernoulli</option>
                            <option value="bin">Binomial</option>
                            <option value="neg">Negative Binomial</option>
                            <option value="geo">Geometric</option>
                            <option value="hyp">Hypergeometric</option>
                            <option value="poi">Poisson</option>
                            <option value="con">Conway-Maxwell-Poisson</option>
                            <option value="zip">Zipf</option>
                            </select>
                        </div>
                        <div>
                        {selectedText==="dis" && <DistributionThreeEntries endpoint={"discrete-uniform"} placeHolder1={"Lower bound (integer)"} placeHolder2={"Upper bound (integer)"} placeHolder3={"k (default 1.0)"}></DistributionThreeEntries>}
                        {selectedText==="ber" && <DistributionTwoEntries endpoint={"bernoulli"} placeHolder1={"Probability p"} placeHolder2={"k (default 1.0)"}></DistributionTwoEntries>}
                        {selectedText==="bin" && <DistributionThreeEntries endpoint={"binomial"} placeHolder1={"Success probability"} placeHolder2={"Number of trials (integer)"} placeHolder3={"Successes k"}></DistributionThreeEntries>}
                        {selectedText==="neg" && <DistributionThreeEntries endpoint={"negative-binomial"} placeHolder1={"Number of successes r"} placeHolder2={"Success probability p"} placeHolder3={"Trials k (default 10)"}></DistributionThreeEntries>}
                        {selectedText==="geo" && <DistributionTwoEntries endpoint={"geometric"} placeHolder1={"Probability p"} placeHolder2={"Trials k (default 1.0)"}></DistributionTwoEntries>}
                        {selectedText==="hyp" && <DistributionFourEntries endpoint={"hypergeometric"} placeHolder1={"Population size (integer) "} placeHolder2={"Number of successes (integer)"} placeHolder3={"Number of draws (integer)"}  placeHolder4={"k (default 10.0)"}></DistributionFourEntries>}
                        {selectedText==="poi" && <DistributionTwoEntries endpoint={"poisson"} placeHolder1={"Rate λ"} placeHolder2={"x (default 10.0)"}></DistributionTwoEntries>}
                        {selectedText==="con" && <DistributionThreeEntries endpoint={"conway-maxwell-poisson"} placeHolder1={"λ"} placeHolder2={"v"} placeHolder3={"x (default 5.0)"}></DistributionThreeEntries>}
                        {selectedText==="zip" && <DistributionThreeEntries endpoint={"zipf"} placeHolder1={"Shape parameter"} placeHolder2={"Mode (integer)"} placeHolder3={"k (default 5.0)"}></DistributionThreeEntries>}



                        </div>
                        <div className="py-12"> 
                        {/*  <StatisticsAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>


        </>
    )
}
export default StatisticsDiscrete;
