import {React} from "react";
import {Helmet} from "react-helmet";
import ScienceTemplate from "./ScienceTemplates";



function SciencePhysics(){
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Physical constants" />
        </Helmet>        
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title sm:text-2xl text-xl text-sky-400  flex justify-center items-center py-2">Physical constants</h1>
                    <p>
                    Physical constants are the backbone of our understanding of the universe. The most famous physical constants are the Speed of Light (c),
                    Gravitational Constant (G), Elementary Charge (e), Avogadro's Number (N <sub>A</sub>) and, Boltzmann Constant (k <sub>B</sub>).
                    </p><br />
                        <div>
                            <> <ScienceTemplate endpoint={"physics"} ></ScienceTemplate>   </>     
                        </div>
                        <div className="card shadow w-full">
                             
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default SciencePhysics;