import {AlgebraOneEntry} from "./AlgebraTemplates";
    import {React} from "react";
    import AlgebraAffiliate from "./AlgebraAffiliate";



function AlgebraFibonacci(){
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h1 className="card-title flex justify-center items-center py-2 text-sky-500">Fibonacci numbers</h1>
                    <p>Fibonacci numbers form a sequence where each number is the sum of the two preceding ones. It starts like this:
                        0, 1, 1, 2, 3, 5, 8, 13, 21, ...

                    </p><br /><hr /><br />
                        <div>
                            <AlgebraOneEntry endpoint={"fibonacci"}  placeHolder1={"Enter a positive integer"} ></AlgebraOneEntry> 
                        </div>

                        <div className="py-12"> 
                        {/*  <AlgebraAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    
                </div>
            </section>
            </>
    )
}

export default AlgebraFibonacci;