import {React, useState} from "react";
import {Helmet} from "react-helmet";
import {FunctionsOneEntry, FunctionsTwoEntries} from "../functions/FunctionsTemplates";


function FunctionsMain(){
    const [selectedText, setSelectedText] = useState('Factorial') //default selected value
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Mathematical functions" />
          <meta property="og:description" content="Covers Factorials, Gamma, Beta, Stirling, Error functions."/>
        </Helmet>        
            {/**Description */}
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-2 mx-auto">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="sm:text-2xl text-xl mx-auto font-bold text-sky-500 py-4">Mathematical Functions</h1>
                        <p className="text-black-500 dark:text-white">
                        Mathematical Functions, as its name suggests, helps compute various functions such as factorials and double factorials. 
                        Here is the list of the topics covered: 
                        </p>
                        <ul className="space-y-4 py-4 px-12">
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Factorials</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Gamma function</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Beta function</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Stirling function</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> Error function</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white"> GCD </p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white">LCM</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="text-base-content body-font relative dark:text-white">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <div className="flex flex-col text-center w-full mb-12">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="Factorial">Factorial</option>
                                <option value="Gamma">Gamma</option>
                                <option value="Beta">Beta</option>
                                <option value="Stirling">Stirling</option>
                                <option value="Error">Error</option>
                                <option value="Binomial">Binomial</option>
                                <option value="GCD">Greatest Common Divisor</option>
                                <option value="LCM">Lowest Common Multiplicator</option>
                            </select>
                        </div>
                        <div>
                            {selectedText==="Factorial" && <FunctionsOneEntry endpoint={"factorial"} placeHolder1={"Enter an integer"}></FunctionsOneEntry>}
                            {selectedText==="Gamma" && <FunctionsOneEntry endpoint={"gamma"} placeHolder1={"Enter an integer"}></FunctionsOneEntry>}
                            {selectedText==="Beta" && <FunctionsTwoEntries endpoint={"beta"} placeHolder1={"z"} placeHolder2={"w"}></FunctionsTwoEntries>}
                            {selectedText==="Error" && <FunctionsOneEntry endpoint={"error"} placeHolder1={"Enter a value"}></FunctionsOneEntry>}
                            {selectedText==="Stirling" && <FunctionsTwoEntries endpoint={"stirling"} placeHolder1={"n"} placeHolder2={"k"}></FunctionsTwoEntries>}
                            {selectedText==="Binomial" && <FunctionsTwoEntries endpoint={"binomial"} placeHolder1={"n"} placeHolder2={"k"}></FunctionsTwoEntries>}
                            {selectedText==="GCD" && <FunctionsTwoEntries endpoint={"gcd"} placeHolder1={"Value A"} placeHolder2={"Value B"}></FunctionsTwoEntries>}
                            {selectedText==="LCM" && <FunctionsTwoEntries endpoint={"lcm"} placeHolder1={"Value A"} placeHolder2={"Value B"}></FunctionsTwoEntries>}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>


        </>
    )
}
export default FunctionsMain;
